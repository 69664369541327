import { FormattedMessage, useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Label } from '../../../shared/Label';
import { Input } from '../../../shared/Input';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import { isValidPhoneNumber } from 'libphonenumber-js/min';
import { axiosInstance } from '../../../../network/apis';
import { useHistory, useParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { components, OptionProps } from 'react-select';
import { debounce } from 'lodash';
import { LOCALE } from '../../../../_metronic/helpers/typescript';
import DatePicker from 'react-datepicker';
import { Portal } from '../../../../_metronic/partials';
import AhadDialog from '../../../../setup/shared/AhadDialog';

interface IParams {
  userId: string;
}

export default function UserEdit() {
  const history = useHistory();

  const { formatMessage, locale } = useIntl();
  const params: IParams = useParams();
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  /* --------------------------------- Formik --------------------------------- */

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      image: '',
      available_companies_count: 0,
      plans: [] as { label: string; value: string }[],
      expiration_date: '',
    },
    validationSchema: Yup.object().shape({
      available_companies_count: Yup.number()
        .typeError(formatMessage({ id: 'Only number are allowed!' }))
        .min(0, formatMessage({ id: 'The Minimum number is {minNumber}' }, { minNumber: 0 }))
        .max(999, formatMessage({ id: 'The Maximum number is {maxNumber}' }, { maxNumber: 999 }))
        .required(formatMessage({ id: 'This field is required' })),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const data = {
        available_companies_count: values.available_companies_count,
        default_plan_id: values.plans[0]?.value,
        default_plan_expiration_date: values.expiration_date,
      };

      axiosInstance
        .put(`admin/users/${params.userId}`, data)
        .then((response) => {
          toast.success(formatMessage({ id: 'user profile has been updated successfully' }));
          setLoading(false);
          history.push(`/admin/users`);
        })
        .catch((error) => {
          setLoading(false);
          const data = error.response.data.errors;
          const merged: any = Object.values(data).reduce(
            (accumulator: any, currentValue) => accumulator.concat(currentValue),
            []
          );
          merged.map((item: any) =>
            toast.error(item, {
              toastId: 'available_companies_count',
            })
          );
        });
    },
  });

  const { setFieldValue, values } = formik;
  /* --------------------------------- end Formik --------------------------------- */

  /* ------------------------- get client data ------------------------ */

  const getPlansOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`admin/plans`, {
          params: {
            name: inputValue,
            per_page: 0,
            type: '2',
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((plan: any) => ({
              ...plan,
              label: plan?.name?.[locale as LOCALE],
              value: plan.id,
            }))
          );
        });
    }, 1000),
    []
  );

  const getUserInfo = useCallback(() => {
    if (!params.userId) return;
    axiosInstance
      .get(`admin/users/${params.userId}`)
      .then((response) => {
        const res = response.data;
        // setAllUserData(res);
        setFieldValue('first_name', res?.first_name);
        setFieldValue('last_name', res?.last_name);
        setFieldValue('email', res?.email);
        setFieldValue('phone', res?.phone);
        setFieldValue('image', res?.image);
        setFieldValue('available_companies_count', res?.available_companies_count);
        setFieldValue('expiration_date', res?.default_plan_expiration_date);
        setFieldValue(
          'plans',
          res?.default_plan?.id
            ? [
                {
                  label: res?.default_plan?.name,
                  value: res?.default_plan?.id,
                },
              ]
            : []
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      {/*  ---------------------user approval confirmation Modal-----------------------  */}
      {showConfirmationModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => {
              setShowConfirmationModal(false);
            }}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                <span>
                  {formatMessage({
                    id: 'Note: When changing the assign plan or the expiration date, these changes will only apply to companies that have not yet been created.Are you sure you want to proceed with these changes?',
                  })}
                </span>
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowConfirmationModal(false);
                        formik.submitForm();
                      }}
                      type='button'
                      id='modal_approve'
                      className='btn btn-sm btn-success btn-shadow btn-success-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Approve' />
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowConfirmationModal(false);
                      }}
                      type='button'
                      id='modal_cancel'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end user approval confirmation Modal-----------------------  */}
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Personal Information' })}</PageTitle>

      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
          paddingRight: locale === 'en' ? '4em' : '',
          paddingLeft: locale === 'ar' ? '4em' : '',
        }}
      >
        <div className='row'>
          <div className='col-6'>
            <img
              src={values.image === '' ? toAbsoluteUrl('/media/avatars/blank.png') : values.image}
              className='rounded-circle w-150px me-3'
              alt='thumb'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'First Name' })}
              autoComplete='off'
              labelId='First Name'
              formik={formik}
              name='first_name'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'Last Name' })}
              autoComplete='off'
              labelId='Last Name'
              formik={formik}
              name='last_name'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'Email' })}
              autoComplete='off'
              labelId='Email'
              formik={formik}
              name='email'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'number of available companies' })}
              autoComplete='off'
              labelId='number of available companies'
              formik={formik}
              name='available_companies_count'
              type='text'
              disabled={false}
            />
          </div>
        </div>

        <div className='row gx-10 mb-5'>
          {/*begin::Col*/}
          <div className='col-lg-6'>
            {/*  --------------------- Plans select-----------------------  */}

            <Label msg='Assigned Plan' htmlFor='Assigned Plan' />
            <AsyncSelect
              key={JSON.stringify(`plan`)}
              onChange={(value) =>
                value !== null ? setFieldValue('plans', [value]) : setFieldValue('plans', [])
              }
              onBlur={() => formik.setFieldTouched('plans', true)}
              value={formik.values?.plans}
              // name='plans'
              className='form-control form-control-md p-0'
              id='plan'
              cacheOptions
              loadOptions={getPlansOptions}
              defaultOptions
              placeholder={formatMessage({
                id: 'Assigned Plan',
              })}
              isClearable
              menuPlacement='auto'
              noOptionsMessage={() => {
                return (
                  <span role='alert' className='text-danger'>
                    {formatMessage({
                      id: 'No options found',
                    })}
                  </span>
                );
              }}
              loadingMessage={() => (
                <div className='d-flex justify-content-center'>
                  <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              )}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 2,
                  paddingBottom: 2,
                  zIndex: 3,
                }),
                menuPortal: (base: any) => ({
                  ...base,
                  zIndex: 9999,
                }),
                noOptionsMessage: (base: any) => ({
                  ...base,
                  color: 'red',
                }),
              }}
              components={{
                Option: ({ children, ...props }: OptionProps<any, false>) => {
                  return (
                    <components.Option {...props}>
                      <div className='d-flex justify-content-between'>
                        <span>{props.data?.label}</span>
                      </div>
                    </components.Option>
                  );
                },
              }}
            />
            <ErrorMessage name='plans' formik={formik} />

            {/*  --------------------- end Plans select-----------------------  */}
          </div>

          <div className='col-lg-6'>
            <Label msg='Expiry date' />

            <DatePicker
              selected={
                formik.values.expiration_date ? new Date(formik.values.expiration_date) : null
              }
              dateFormat='dd/MM/yyyy'
              placeholderText={formatMessage({
                id: 'Expiry date',
              })}
              minDate={new Date()}
              id='expiration_date_id'
              className='form-control fw-bolder pe-5  basic-input'
              onChange={(date: any) =>
                formik.setFieldValue(
                  `expiration_date`,
                  date
                    ? // remove offset
                      new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
                    : ''
                )
              }
              onChangeRaw={(e) => {
                formik.setFieldTouched(`expiration_date`, true, true);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              autoComplete='off'
            />
            <ErrorMessage name='expiration_date' formik={formik} />
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Label msg='Phone Number' />
            <div style={{ maxWidth: 'fit-content' }} dir='ltr'>
              <PhoneInput
                disabled={true}
                localization={locale === 'ar' ? ar : undefined}
                inputClass='form-control form-control-sm b-radius-16 basic-input'
                country={'sa'}
                placeholder={formatMessage({ id: 'Phone' })}
                value={formik.values.phone}
                onChange={(phone, data: any) => {
                  const valid = isValidPhoneNumber(
                    phone.slice(data.dialCode.length),
                    data.countryCode
                      .split('')
                      .map((l: string) => l.toUpperCase())
                      .join('')
                  );
                  formik.setFieldValue(`phone`, phone);
                  formik.setTouched({ ...formik.touched, phone: true });
                  formik.setFieldValue(`phoneValidity`, valid);
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='mt-4 col-xl-4'>
            <div className='col-md-6'>
              <button
                // type='submit'
                className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow mt-5'
                id='my-form'
                disabled={loading}
                onClick={
                  (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowConfirmationModal(true);
                  }
                  // formik.submitForm
                }
              >
                {!loading && (
                  <span className='indicator-label'>
                    <FormattedMessage id='Confirm' />
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <FormattedMessage id='Please wait...' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
