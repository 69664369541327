import { FC, ReactElement } from 'react';
import { EditViewCompanyDetailsWrapper } from '../../EditViewCompanyDetailsWrapper';
// import { CompanyProfile } from '../containers/CompanyProfile';
// import { CompanyBranding } from '../containers/CompanyBranding';
// Hide the unnecessary
// import { CompanyContacts } from '../containers/CompanyContacts';
// import { CompanyGroups } from '../containers/CompanyGroups';
// import { CompanyDocuments } from '../containers/CompanyDocuments';
// import { CompanySettings } from '../containers/CompanySettings';
// import { CompanyIntegrations } from '../containers/CompanyIntegrations';
// import { useParams } from 'react-router-dom';

type TAB = {
  id: number;
  tabId: string;
  tabIcon: string;
  viewcomponent: ReactElement;
  editComponent: ReactElement;
  display: boolean;
};

export const COMPANY_DETAILS_TABS: TAB[] = [
  {
    id: 1,
    tabId: 'Company Profile',
    tabIcon: 'fa-briefcase',
    viewcomponent: <EditViewCompanyDetailsWrapper mode={'view'} displayRequiredSection={1} />,
    editComponent: <EditViewCompanyDetailsWrapper mode={'edit'} displayRequiredSection={1} />,
    display: true,
  },
  {
    id: 2,
    tabId: 'Branding',
    tabIcon: 'fa-lightbulb',
    viewcomponent: <EditViewCompanyDetailsWrapper mode={'view'} displayRequiredSection={2} />,
    editComponent: <EditViewCompanyDetailsWrapper mode={'edit'} displayRequiredSection={2} />,
    display: true,
  },
  {
    id: 3,
    tabId: 'Tax settings',
    tabIcon: 'fa-cog',
    viewcomponent: <EditViewCompanyDetailsWrapper mode={'view'} displayRequiredSection={3} />,
    editComponent: <EditViewCompanyDetailsWrapper mode={'edit'} displayRequiredSection={3} />,
    display: true,
  },
  {
    id: 4,
    tabId: 'Company Activities & Categories',
    tabIcon: 'fa-network-wired',
    viewcomponent: <EditViewCompanyDetailsWrapper mode={'view'} displayRequiredSection={4} />,
    editComponent: <EditViewCompanyDetailsWrapper mode={'edit'} displayRequiredSection={4} />,
    display: true,
  },
  {
    id: 5,
    tabId: 'Invoices configurations',
    tabIcon: 'fa-chart-bar',
    viewcomponent: <EditViewCompanyDetailsWrapper mode={'view'} displayRequiredSection={5} />,
    editComponent: <EditViewCompanyDetailsWrapper mode={'edit'} displayRequiredSection={5} />,
    display: true,
  },
  {
    id: 6,
    tabId: 'Bank information',
    tabIcon: 'fa-chart-bar',
    viewcomponent: <EditViewCompanyDetailsWrapper mode={'view'} displayRequiredSection={6} />,
    editComponent: <EditViewCompanyDetailsWrapper mode={'edit'} displayRequiredSection={6} />,
    display: true,
  },
  // {
  //   id: 3,
  //   tabId: 'Tax settings',
  //   tabIcon: 'fa-cog',
  //   component: <CompanySettings mode={'view'} />,
  //   editComponent: <CompanySettings mode={'edit'} />,
  //   param: 'settings',
  //   editparam: 'settings/edit',
  // },
  // Hide the unnecessary
  // {
  //   id: 3,
  //   tabId: 'Contacts',
  //   tabIcon: 'fa-envelope',
  //   component: <CompanyContacts />,
  //   param: 'contacts',
  // },
  // {
  //   id: 4,
  //   tabId: 'Groups',
  //   tabIcon: 'fa-network-wired',
  //   component: <CompanyGroups />,
  //   param: 'groups',
  // },
  // {
  //   id: 5,
  //   tabId: 'Documents',
  //   tabIcon: 'fa-file-alt',
  //   component: <CompanyDocuments />,
  //   param: 'documents',
  // },

  // {
  //   id: 7,
  //   tabId: 'Integrations',
  //   tabIcon: 'fa-cube',
  //   component: <CompanyIntegrations />,
  //   param: 'integrations',
  // },
];

export const companyDetailsParamsMapView = new Map(
  COMPANY_DETAILS_TABS.map((tab: TAB) => [tab.id, { ...tab }])
);
export const companyDetailsParamsMapEdit = new Map(
  COMPANY_DETAILS_TABS.map((tab: TAB) => [tab.id, { ...tab }])
);

type CompanyDetailsHandlerProps = { currentView: number; mode: string };

export const CompanyDetailsHandler: FC<CompanyDetailsHandlerProps> = ({ currentView, mode }) => {
  // const params: any = useParams();
  // console.log(currentView);
  //TODO : fix this that it would be like seprate componnents
  if (mode === 'view') {
    return companyDetailsParamsMapView.get(currentView)?.viewcomponent || null;
  } else if (mode === 'edit') {
    return companyDetailsParamsMapEdit.get(currentView)?.editComponent || null;
  } else {
    return null;
  }
};
